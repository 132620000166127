

.cell-align-right{
    text-align: right;
    /* padding-right: 1rem; */
  }

.cell-bold{
    /* padding-right: 1rem; */
    font-weight:700;
    /* border: 1px solid red; */
}

.owners-statement .totals-row th {
  /* font-weight: bold; */
  font-weight:600 ;
}

.collection-report table, .owners-statement table{
  font-size:.8rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  width: 100%;

}

.collection-report table th .cell-bold, .owners-statement table th .cell-bold{
  font-weight:700;
  color: blue;
}

.collection-report table th:first-child, .collection-report table td:first-child, .owners-statement table th:first-child, .owners-statement table td:first-child{
  padding-left: 0.5rem;
}

.collection-report table th:last-child, .collection-report table td:last-child, .owners-statement table th:last-child, .owners-statement table td:last-child{
  padding-right: 0.5rem;
}


.collection-report table th, .owners-statement table th{
  font-weight: 300;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.collection-report table thead, .owners-statement table thead{
  background-color: #cfd8dc;
}

.collection-report table tfoot th, .owners-statement table tfoot th{
  font-weight: 600;
}


.collection-report .menu-icon, .owners-statement .menu-icon{
  /* color: #166281  */
  color: #00bcd4;
  /* max-height: 100px; */
  padding: unset;

}

.collection-report .menu-button-filter, .owners-statement .menu-button-filter{
  height: 30px;

}

.collection-report .menu-button-filter svg, .owners-statement .menu-button-filter svg{
  color: #00bcd4;
  height: 25px;
}


.deductions-schedule {
  border-top: 1px solid #1976d2;
  margin-top: 4rem;
  page-break-before: always;
}

.deductions-schedule h3 {
  font-size: 1.4rem;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}

.schedule-section {
  margin-bottom: 2rem;
}

/* .schedule-section h4 {
  margin-bottom: 1rem;
} */

.schedule-section .subtitle {
  /* margin-bottom: 1rem; */
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 500;
  color: #607d8b;
  margin-bottom: unset;
}

.schedule-section table {
  width: 100%;
  margin-bottom: 1.5rem;
  margin-top: 0.5rem;
}

.subtotal-row {
  font-weight: bold;
  border-top: 2px solid #ddd;
}



