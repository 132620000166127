

.cell-align-right{
    text-align: right;
    /* padding-right: 1rem; */
  }

.cell-bold{
    /* padding-right: 1rem; */
    font-weight:700;
    /* border: 1px solid red; */
}

.end-month-report .totals-row th {
  /* font-weight: bold; */
  font-weight:600 ;
}

.end-month-report {
    width: 100%;
    overflow-x: hidden; 
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

/* Add a wrapper div around your table with these styles */
.end-month-report .table-container{
    overflow-x: auto;
    width: 100%;
}

/* .collection-report table, .owners-statement table{ */
.end-month-report table{
  font-size:.8rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  width: 100%;

}

.end-month-report table th{
    white-space: nowrap;  /* Allows text to wrap */
    padding: 0.5rem;        /* Adds some padding for better readability */
    font-weight: 300;
}

/* .collection-report table th .cell-bold, .owners-statement table th .cell-bold{ */
.end-month-report table th .cell-bold{
  font-weight:700;
  color: blue;
}

/* .collection-report table th:first-child, .collection-report table td:first-child, .owners-statement table th:first-child, .owners-statement table td:first-child{ */
/* table th:first-child{
  padding-left: 0.5rem;
  background-color: #cfd8dc;

} */

/* Make first column sticky */
.end-month-report table th:first-child
{
    position: sticky;
    left: 0;
    z-index: 2;  
    background-color: #cfd8dc;
    width: 1.75rem;
    padding-right: 0;
}

.end-month-report table td:first-child {
    position: sticky;
    left: 0;
    z-index: 2;  
    background-color: #edf3f8; 
    width: 1.75rem;
    padding-right: 0;
}


.end-month-report table th:nth-child(2){
    position: sticky;
    left: 1.75rem;
    z-index: 2;  
    background-color: #cfd8dc;
}

.end-month-report table td:nth-child(2) {
    position: sticky;
    left: 1.75rem;
    z-index: 2;  
    background-color: #edf3f8; 
}

.end-month-report table th:nth-child(2),
.end-month-report table td:nth-child(2) {
    width: auto;        /* allows column to grow based on content */
    white-space: nowrap; /* prevents text wrapping */
    padding-right: 2rem;
}

.end-month-report table th:first-child{
    padding-left: 0.5rem;

}

/* .collection-report table th:last-child, .collection-report table td:last-child, .owners-statement table th:last-child, .owners-statement table td:last-child{ */
.end-month-report table th:last-child{
  padding-right: 0.5rem;
}


/* .collection-report table th, .owners-statement table th{ */



.end-month-report table td{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    /* border: 1px solid #ddd; */
    /* border-right: 1px solid #ddd; */

}

.end-month-report table tfoot td{
  font-weight: 600;
  font-size: 0.85rem;
  padding-top: 0.5rem;
  border-top: 1px solid lightgray;

}

/* .collection-report table thead, .owners-statement table thead{ */
.end-month-report table thead{
  background-color: #cfd8dc;
}

/* .collection-report table tfoot th, .owners-statement table tfoot th{ */
.end-month-report table tfoot th{
  font-weight: 600;
}


/* .collection-report .menu-icon, .owners-statement .menu-icon{ */
.end-month-report .menu-icon{
  /* color: #166281  */
  color: #00bcd4;
  /* max-height: 100px; */
  padding: unset;

}

/* .collection-report .menu-button-filter, .owners-statement .menu-button-filter{ */
.end-month-report .menu-button-filter{
  height: 30px;

}

/* .collection-report .menu-button-filter svg, .owners-statement .menu-button-filter svg{ */
.end-month-report .menu-button-filter svg{
  color: #00bcd4;
  height: 25px;
}


/* .deductions-schedule {
  border-top: 1px solid #1976d2;
  margin-top: 4rem;
  page-break-before: always;
}

.deductions-schedule h3 {
  font-size: 1.4rem;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}

.schedule-section {
  margin-bottom: 2rem;
}


.schedule-section .subtitle {
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 500;
  color: #607d8b;
  margin-bottom: unset;
}

.schedule-section table {
  width: 100%;
  margin-bottom: 1.5rem;
  margin-top: 0.5rem;
} */

.end-month-report .subtotal-row {
  font-weight: bold;
  border-top: 2px solid #ddd;
}



