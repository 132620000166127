.custom-tree-select {
    margin-top: 1.5rem;
}

.ant-select-selector {
    /* border: 1px solid blue; */
    /* max-height: 51px; */
    /* overflow: auto; */
}

.dashboard-form .ant-select-single.ant-select:not(.ant-select-customize-input) .ant-select-selector, 
.dashboard-form .ant-select-multiple .ant-select-selector
{
    /* border: 1px solid blue; */
    height: 3.5rem;
    background-color: #F9FBFD;
}

  
.ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
    /* border: 1px solid green;  */
    /* align-items: center;
    align-content: center;*/
    margin-top: 0.75rem; 
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{
    margin-top: 0.8rem; 
    /* align-items: center; */
    /* border: 1px solid red;  */
}