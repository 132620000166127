
.dashboard-settings{
    display: grid;
    grid-template-columns: 20% 80%;
    background: #edf3f8;
    overflow: auto;

}

.dashboard-settings .settings-menu{
    border-right: 0.25rem solid white;
    /* background: #eeeeea */
    overflow: auto;
    padding-top: 1rem;
}

.dashboard-settings .settings-menu .MuiListItemButton-root{
    padding-top: 3px;
    padding-bottom: 3px;

}

.dashboard-settings .settings-menu .MuiListItemIcon-root{
    min-width: unset;
    padding-right: 5px;
}

.dashboard-settings .settings-menu .MuiListItemIcon-root svg{
    font-size: 1.25rem;
}

/* .dashboard-settings .settings-menu .css-1tfeiaa-MuiTypography-root{
    font-size: 0.8rem;
} */

.dashboard-settings .settings-menu .MuiListItemText-root span{
    font-size: 0.8rem;
}

.dashboard-settings .dashboard-content{
    overflow: auto;
    /* margin-left: 5%; */
    margin: unset;
    padding-left: 5%;
    padding-right: 7.5%;
    align-content: start;
}