

.journal-voucher-table .styled-table {
    background-color: transparent;
    border-collapse: separate;
    border-spacing: 5px;
    /* td,th{
      background-color: #f9fbfd;
      padding-top: 0.65rem;
      padding-bottom: 0.65rem;
    } */
  }
  
  
  .journal-voucher-table .styled-table td,
  .journal-voucher-table .styled-table th {
    background-color: #f9fbfd;
    padding-top: 0.65rem;
    padding-bottom: 0.65rem;
    /* padding-left: 0.85rem;
    padding-right: 0.85rem; */
    /* padding: 1rem; */
    /* white-space: nowrap; */
    /* overflow: auto; */
  }
  
  
  .journal-voucher-table .styled-table td:first-child,
  .journal-voucher-table .styled-table th:first-child,
  .journal-voucher-table .styled-table td:nth-child(4),
  .journal-voucher-table .styled-table th:nth-child(4) {
    width: 36%;
    padding-left: 1rem;
  }
  
  .journal-voucher-table .styled-table td:nth-child(2),
  .journal-voucher-table .styled-table th:nth-child(2),
  .journal-voucher-table .styled-table td:nth-child(3),
  .journal-voucher-table .styled-table th:nth-child(3) {
    width: 12.5%;
    text-align: right;
    padding-right: 1rem;
  }
  
  .journal-voucher-table .styled-table td:last-child button {
    /* background-color: #f9fbfd; */
    padding: 0;
    margin: 0;
  }






  /* .journal-voucher-table{
  .styled-table {
    background-color: transparent;
    border-collapse: separate;
    border-spacing: 5px;
    td, th{
      padding: 0;
      margin: 0;
   
    }
    th{
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
    }
    td{
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
    width: 100%; 

    td:last-child button {
      padding: 0;
      margin: 0;
         }


    td:first-child,th:first-child, th:last-child, td:nth-child(4),th:nth-child(4) {
      width: 37.5%;
      padding-left: 1rem;
    }
    td:nth-child(2),th:nth-child(2), td:nth-child(3),th:nth-child(3){
      width: 12.5%;
      text-align: right;
      padding-right: 1rem;
      background-color: #f9fbfd;


    }
    td, th {
      background-color: #f9fbfd;
    }
  }
} */