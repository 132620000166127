/*Responsive Design Mobile First*/

html, body {
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
  font-variant: normal; /* Override default tabular-nums */
  height: 100%;
}

#root {
  /* position: absolute; */
  /*top: 0;
  left: 0;
  height: 100%;
  width: 100%; */
  height: 100%;
}


.container-fluid{
  height: 100%;
  display: grid;

  /* border: solid 1px blue; */
}

.hero-bg-lightblue{
  /* height: 45%; */
  height: 125vw;
  background-color: #f0f5ff;
}

.container{
  display: grid;
  gap: 2rem;
  /* /* min-height: 100%; */
  height: 100%; 

}


.navbar {
  display: flex;
  height: 70px;

}

/* .navbar img{
  object-fit: contain; 
  height: 100%;
} */

/* .navbar a{
  font-size: 1.5rem;
} */

/* .nav-brand{
  margin-right: auto;
} */

.navbar-brand{
  /* height: 90px; */
  background-size :contain;
  background-repeat: no-repeat;
}

.navbar-brand img{
  object-fit: contain; 
  /* height: 40px;  */
  height: 20px; 


}

.navbar .login-button{
  /* text-transform: none; */
  /* border: #F6B21B solid 2px; */
  /* border: #F6B21B solid 1px; */
  border: #ff9800 solid 0.1px;

  font-size: 1.15rem;
  width: 7.5rem;
  background-color: transparent;
  border-radius: 5%;
  /* height: 50px; */
  height: 40px;

  font-weight: 300;

}
.navbar .login-button:hover{
  border: #b5179e solid 1px;
}



/* .nav-icon{ */
.navbar-icon{
  display: flex;
  align-items: center;
  /* font-size: 1.5rem; */
  font-size: 2rem;
  font-weight: 900;
  border: #F6B21B solid 2px;
  /* border: #e76f51 solid 2px; */

  border-radius: 20%;
  padding: 5px;
  /* height: 45px; */
  height: 40px;

}
.navbar-icon svg{
  color: #314B66 ;
  /* color: #F6B21B ; */
  /* color: #f9a825; */

}

.nav-icon:hover{
  /* font-size: 1.75rem; */
  color: #b5179e;
}



.hero{
  display: grid;
}

.hero-title{
  justify-self: center;
  text-align: center;
  font-size: 2rem;
  font-weight: 200;
  padding: 0;
  /* padding: 0 20vw; */
  line-height: 2.25rem;
  margin-top: 2rem;
}

.hero-subtitle{
  justify-self: center;
  text-align: center;
  font-size: 0.8rem;
  font-weight: 300;
  /* padding: 0 20vw; */
  /* padding: 0 5%; */
  padding: 0 5%;

  line-height: 1rem;
  margin-top: 1rem;
}

.hero-body{
  display: grid;
  justify-self: center;
  text-align: center;
  margin-top: 2rem;
}

.hero button{
  margin-top: 1rem;
  width: 225px;

  height: 3rem;
  justify-self: center;
  /* background-color: #b5179e; */
  /* background-color: #314B66; */
  background-color: #166281;
  /* background-color: #0f4c5c; */


  /* color: #314B66; */
}

.hero-img{
  object-fit: cover; 
  /* width: 80vw;  */

}

.hero-img img{
  /* height: 100%; */
  width: 100%;
}


/* .container-lower{
  margin-right: 7.5%;
  margin-left: 7.5%;
  display: grid;

} */

/* .main{
  display: grid;
  gap: 2rem;
}

.main-image{
  height: 290px;
  width: 100%;
} */

.features{
  display: flex;
  gap: 1.5rem;
  overflow-y: scroll;
  height:150px;
}

.features-icon{
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #f4a261;
  /* color: #e76f51; */

  /* color: #ef7819; */
  /* color: #219ebc; */


  
}

.features-card {
  /* font-size: 0.75rem; */
  /* font-size: 1rem; */
  font-size: 0.8rem;
  height: 150px;
  min-width: 90%;

  /* background-color: #f0f5ff; */
  /* background-color: #fff0f680; */
  background-color:  rgba(240, 245, 255, 0.4);
  /* background-color:  #ededed; */

  /* background-color:  #fff8f0; */
  /* background: rgba(255, 240, 246, 0.4); */


  padding: 1rem;
  /* box-shadow: 0px 1px #b5179e; */
  border-left: 1px solid #b5179e;

}

.features-card-title {
  /* font-size: 0.8rem; */
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
}

.features-card-body {
  /* padding: 1rem; */
  font-weight: 200;
  line-height: 0.9rem;
}

.clients{
  /* height: 10rem; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* border: 1px solid red */
  /* margin-bottom: 2rem; */
  /* margin-bottom: 1.5rem; */
}

.clients-heading{
  font-size: 1.15rem;
  font-weight: 600;
  line-height: 44px;
  text-align: center;
}

.clients-text{
  font-size: 0.65rem;
  /* padding-left: 25%;
  padding-right: 25%; */
  text-align: center;
  margin-bottom: 1.5rem;
}

.clients-logos-container{
  display: flex;
  justify-content: center;
  /* overflow-y: scroll; */

  /* border: 1px solid red */
  flex-wrap: wrap; /* Add this property to wrap logos */
  /* padding: ; */
}

.clients-logo{
  /* height: 50px; */
  height: 40px;
  padding-left: 1rem;
  padding-right: 1rem;
}


.cta{
  height: 215px;

  /* height: 12.5rem; */

  /* background-color: #1769aa; */
  background-color: #219ebc;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}

.cta-text{
  color: white;
  /* font-size: 0.8rem; */
  font-size: 0.75rem;

  text-align: center;
  padding: 0 1rem;
  /* margin: 1rem; */
  /* font-weight: 500; */
  /* margin-left: 5%; */
}
.cta-button{
  /* align-self: center; */
  color: white;
  display: flex;
}

.cta-button button{
  color: white;
  border-color: white;
}

.landing-form-container{
  /* margin: 0 7.5%; */
  /* height: 500px;
  padding-top: 2rem;*/
  /* padding-bottom: 10%;  */
  padding-bottom: 2rem;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

.landing-form-bg-purple{
  /* Duplicate (to replace) of auth-img */
  display:none; 
  align-items: center;
  justify-items: center;
  /* background: #702670; */
  /* background: #702670; */
  /* background-color: #219ebc; */
  /* background-color: #909cc2; FIRST*/
  background-color: #33658a;


  
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  flex-direction: column;
  justify-content: center;
}

.landing-form {
  /* grid-column: 6 / -1; */
  grid-column: 1/-1;
  background: #f9f8fb;
  border-radius: 12pt;
  display: flex;
  flex-direction: column;
  justify-content: center; 
}

.landing-form form{
  padding: 0 2rem;
}

.landing-form .custom-input, .custom-formcontrol {
  margin-top: 1.5rem;
}

.landing-form .custom-radio-group {
  margin-top: 1.5rem;
}

.landing-form .custom-iconbutton {
  margin-top: 2rem;
}

/* .landing-form .custom-formcontrol .custom-input{
  margin-top: 0;
} */

.landing-form .custom-input label,
.landing-form .custom-input input,
.landing-form .custom-input span,
.landing-form .custom-select,
.landing-form .custom-radio span {

  font-size: 0.9rem;
  /* font-size: 14pt; */

}



/* .landing-form .custom-outlinedinput{
  display: flex;
  align-content: center;
}

.landing-form .custom-endoadornment{
  display: flex;
  align-content: center;
  align-items: center;
} */






.landing-form form button{
  margin-top: 1rem;
  margin-bottom: 2rem;

}

.container-auth {
  margin: 0 7.5%;
  height: 500px;
  padding-top: 2rem;
  padding-bottom: 10%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  /* grid-template-columns: 1fr 1fr; */
}

.auth-img {
  /*Hidden on Mobile */
  display:none; 
  align-items: center;
  justify-items: center;
  background: #702670;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  flex-direction: column;
  justify-content: center;
}

.auth-img-title{
  color: white;
  font-size: 2rem;
  font-weight: 200;
  /* justify-self: center; */
}

.auth-img-subtitle{
  color: white;
  font-size: 0.75rem;
  padding: 0 0.5rem;
  /* font-weight: 200; */
}

.auth-form {
  /* grid-column: 1/-1;
  background: #f9f8fb;
  border-radius: 12pt;
  display: flex;
  flex-direction: column;
  justify-content: center;  */
  /* padding-left: 10rem;*/
  /* border: 1px solid red;  */
  display: flex;
  flex-direction: column;
  /* justify-content: center;  */

}

.auth-form form{
  /* padding: 0 2rem; */
  margin-top: 3rem;
  padding: 0 15%;
  /* border: 1px solid red;  */


  /* padding-left: 10rem; */

}

.auth-form-brand{
  /* height: 40px; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid red;  */

}

.auth-form-brand img{
  /* height: 60%; */
  /* height: 60px; */
  height: 30px;
  margin-bottom: 1rem;

}

.auth-form-avatar-container{
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center; 
}

.auth-form-avatar{
  /* justify-self: center;
  align-self: center; */
}

/* .auth-form button{ */
.auth-form-button{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.auth-form-button button{
  width:100%
}



.container-contact {
  /*height: 100%;*/
  height: 100vh;
  display: grid;
  /*margin-left: 7.5%;
  margin-right: 7.5%;*/
  grid-template-columns: repeat(12, 1fr);
  /*grid-template-rows: 50px auto auto auto;*/
  /* border: 1px solid red;  */
}

.contact-img {
  /* display: grid; */
  grid-column: 1 / 4;
  align-items: center;
  justify-items: center;
  margin-top: 1rem; 
  /*background: #702963;
  background: #43294D;*/
  background: #702670;
  /* height: 100%; */
  /*min-height: 100%;*/
}

.contact-form {
  grid-column: 4 / -1;
  background: #f9f8fb;
  border-radius: 12pt;
  padding: 1rem 5rem;
  /* padding: 1rem; */
  margin-top: 1rem;
}

/* .contact-form label{
  font-size: 0.9rem;
  padding-left: 0;
} */


.contact-form .form-floating label{
  font-size: 0.9rem;
  padding-left: 0;
}

.contact-form input, .contact-form textarea{
  border: 0;
  outline: 0;
  border-radius: 0;
  border-bottom: 1px solid lightgray;
  background-color: #f9f8fb;
  /* padding-left: 12px !important;  */
  font-size: 0.9rem;
  /*margin-bottom: 1rem;*/
  margin-top: 1rem;
  /* margin-left: 10px;  */
}

.contact-form .form-floating input, .contact-form .form-floating textarea{
  border: 0;
  outline: 0;
  border-radius: 0;
  border-bottom: 1px solid lightgray;
  background-color: #f9f8fb;
  padding-left: 3px;
  font-size: 0.9rem;
  /*margin-bottom: 1rem;*/
  margin-top: 1rem;
  /* margin-left: 10px;  */
}

.contact-form .form-floating textarea {
  height: 100px !important;
}

.contact-form button{
  margin-top: 1rem;
}

.dashboard-container{
  display: grid;
  grid-template-columns: 1/-1;
  height: 100%;
}

.dashboard-menu{
  /* Hidden on Mobile */
  flex-direction: column;
  align-self: stretch;
  margin-left: 10%;
  margin-top: 2%;
  border-right: solid;
  border-right-width: thin;
  border-right-color: #d6deea;
}


#sidebar-collapse-acc, #sidebar-collapse-crm{ 
  margin-bottom: 0.5rem;
}

.collapse-nav-link{
  margin-left: 1rem;
  font-size: 0.8rem;
  line-height: 0.5rem;
}

.collapse-nav-link a:last-child{
  border: 1px solid red !important;
}

.dashboard-brand{
  height: 50px;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;

}

.dashboard-brand img{
  object-fit: contain; 
  height: 20px; 
}  

.dashboard-navlink{
  padding-left: 0;
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
}

.dashboard-navlink span{
  padding: 0;
  margin: 0;
  font-size: 0.775rem;
  color: #607d8b;
  /* color: #002984; */
  /* color: #1976d2; */
  /* color: #3f51b5; */
  font-weight: 500;
  /* line-height: 0.25rem; */
  font-family: 'Poppins', sans-serif;
}

.dashboard-collapse{
  /* margin-bottom: 4rem; */
  padding-bottom: 0.5rem;
}

.dashboard-submenu-navlink{
  padding-top: 0.15rem;
  padding-bottom: 0.15rem;
  padding-left: 1.6rem;
}

.dashboard-submenu-navlink span{
  font-weight: 300;
  font-size: 0.75rem;
  /* color: #0d47a1; */
  /* line-height: 0.1rem; */
  /* margin-bottom: 0.25rem; */
}

.dashboard-submenu-text{
  /* border: 1px solid red; */
  margin-top: 0.10rem;
  margin-bottom: 0.10rem;
  /* margin-left: 0.5rem; */
  margin-left: 0.75rem;

}

.dashboard-navlink svg, .dashboard-submenu-navlink svg{
  font-size: 1.2rem;
}

.dashboard-navlink-text{
  margin: 0;
}

.dashboard-navicon{
  /* color: #90127e; */
  /* color: #2196f3; */
  color: #2196f3;
  font-size: 2rem;
  margin-right: 5px;
  /* min-width: none; */
}


.dashboard-navbar li .nav-link {
  color: #1976d2;
  font-weight: 500;
}

.dashboard-navbar li .nav-link svg {
  color: #90127e;

}

.dashboard-navbar li a:hover{
  color: #b5179e;
  font-weight: 500;
  font-size: .85rem;
}

.dashboard-main{
  background: #edf3f8;
  height: 100%;
  overflow: auto;
}

.dashboard-content{
    /* display: grid;  /*CHANGED 06 AUGUST 2024 for chip list container in customers.js */

    margin-left: 7.5%;
    margin-right: 7.5%;
}

.dashboard-content .ant-tabs-nav{
  /* margin-left: 1rem; */
  /* border-bottom:1px solid rgba(0, 0, 0, 0.05); */
  /* border-bottom:1px solid #dee2e6; */
  border-bottom:1px solid #bbdefb;
  
  /* border: 1px solid red; */

}

.dashboard-content .ant-tabs .ant-tabs-tab{
  margin-left: 1rem;
}

.dashboard-content .ant-tabs-tab:first-child {
  margin-left: 0;
}

/* .dasboard-content {
  margin-left: 1rem;
  border: 1px solid red;
} */



.dashboard-loading{
  margin: auto;
}

.dashboard-alert{
  /* margin-top: 1rem; */
}

.dashboard-drawer{
  width: 250px;

}

.dashboard-content-header-nav{
  display: flex;
  flex-direction: row;
}

.dashboard-content-header-nav-icons{
  /* align-self: auto; */
  margin-left: auto;
  margin-top: 1rem;
}

.dashboard-content-header-nav-icons .MuiSvgIcon-root{
  /* border: 1px solid red; */
  width: 2rem;
  height: 2rem;
  color: #166281;

}



.dashboard-content-header{
  margin-top: 1rem;
 
  /* MOVED TO DASHBOARD DIVIDER
  border-bottom: solid;
  border-bottom-width: thin;
  border-bottom-color: #d6deea; */
}

.dasboard-divider, .css-9mgopn-MuiDivider-root{
  border-color: grey;
}

.dashboard-content-pretitle{
  text-transform: uppercase;
  color: #95aac9;
  /* font-size:.625rem */
  font-size:.65rem;
  font-weight: 500;
  
  /* font-size:.7rem */
}

.dashboard-content-title{
  /*font-size:1.625rem;*/
  font-size:1.4rem;
  /* margin-bottom: 2%; */
  margin-bottom: 1rem;

}

.dashboard-content-subheader{
  /* background-color: #f9fbfd;
  border: 0;
  padding: 1rem; */
  margin-top: 1rem;
  display: flex;
  gap: 0.5rem;
}

#dashboard-landing{
  flex-wrap: wrap;

}

.dashboard-content-subheader .dashboard-card {
  background-color: #f9fbfd;
  border: 0;
  padding: 1rem;
  width: 100%;

}

.dashboard-card-pretitle{
  text-transform: uppercase;
  color: #95aac9;
  font-size:.65rem;
  font-weight: 500;
}

.dashboard-card-text{
  font-size:1.5rem;
  font-weight: 300;
  /* padding-left: 0.75rem;
  padding-bottom: 0.75rem; */

}

.settings-card-pretitle{
  /* text-transform: uppercase; */
  /* color: #95aac9; */
  /* color: #00798c; */
  color: #00798c;

  /* font-size:.65rem; */
  font-size:.75rem;

  /* font-weight: 500; */
}

.settings-card-text{
  /* font-size:1.5rem; */
  font-size:1.1rem;
  font-weight: 300;
  /* padding-left: 0.75rem;
  padding-bottom: 0.75rem; */

}

.dashboard-card-chart{
  /* font-size:1.5rem;
  font-weight: 300; */
  /* padding-left: 0.75rem;
  padding-bottom: 0.75rem; */
  height: 100%;
  /* width: 100%; */
  /* padding: 1rem; */
}

.dashboard-card-chart canvas{
  
  /* height: 100%; */
  /* width: 100%; */
  /* border: solid red 1px; */
  /* margin: ; */
}

/* .dashboard-content .dashboard-mobile-navbar{
  display:flex;
  align-items: center;
}

.dashboard-content .dashboard-mobile-navbar .dashboard-icon{
  margin-left:auto;
} */

.dashboard-content .nav-tabs {
  /* margin-top: 1rem; */
  margin-bottom: 1rem;

  /* z-index: 1000; */ 
}

.dashboard-content .fab {
  position: fixed; 
  bottom: 4%;
  right: 1.9%;
  /* z-index: 1000; */ 
}

.dashboard-content .fab:hover {
  background-color: #b5179e ;
}

.dashboard-content .dashboard-card{
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

#settings-card{
  margin-top: 2rem;
  /* margin-bottom: 0.5rem; */
}

.dashboard-content .dashboard-card .dashboard-card-header{
  /* padding: 0.5rem; */
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.dashboard-content .dashboard-card .dashboard-card-content{
  /* padding: 0.5rem; */
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}

.dashboard-content .dashboard-card .dashboard-card-title{
  font-size:1.5rem;
  font-weight: 200;
  /* padding-left: 0.75rem;
  padding-bottom: 0.75rem; */

}

.dashboard-content .dashboard-card .dashboard-card-subtitle{
  text-transform: uppercase;
  /* color: #95aac9; */
  color: #607d8b;
  font-size:0.65rem;
  /* font-weight: 500; */
  font-weight: 500;

  padding-bottom: 0.75rem; 

}

.dashboard-content .dashboard-card p{
  padding: 0;
  margin-bottom: 0.1rem;
  font-size: 0.8rem;
}

.dashboard-content .custom-list-icon{
  font-size: 1.75rem;
  color: #1769aa;
  min-width: 0;
  padding-right: 7.5px;

}
/*Responsive table design #MobileFirst */
/* .styled-table thead{
  display: none;
} 

.styled-table, 
.styled-table tbody, 
.styled-table tr,
.styled-table td{
  display: block; 
  width: 100%
}*/

.income-statement{
  table-layout: fixed;
  width: 50%;
  margin-top: 2rem;
  font-size:.8rem;
  line-height: 1.4rem;
}

.income-statement .blank-row
{
    height: 12px ;
    /* background-color: #FFFFFF; */
}

.income-statement .total-row
{
    font-weight: 700 ;
}



.income-statement tr td:last-child  {
  text-align: right;
}

.income-statement tr:last-child {
  font-size:1.25rem;
  font-weight: 300 ;
}

.financial-report{
  table-layout: fixed;
  width: 50%;
  margin-top: 2rem;
  font-size:.8rem;
  line-height: 1.4rem;
}

.financial-report tr td:last-child  {
  text-align: right;
}

.financial-report .sub-total-row
{
    font-weight: 700 ;
}

.financial-report .total-row
{
  /* font-weight: 700 ; */
  font-size:1.25rem;
  font-weight: 300 ;
}

.financial-report .blank-row
{
    height: 12px ;
}

.styled-table {
  table-layout:auto;
  margin-top: 1rem;
  font-size: 0.8rem;
  background-color: #f9fbfd;
  border-radius:10px;

  /*Testing Tabs */
  width: 100%;

  /* padding: 0 30px; */
  /* border-spacing: 1rem 0; */
}

.styled-table th{
  text-transform: uppercase;
  color: #95aac9;
  font-size:.65rem;
  font-weight: 500;
}

.styled-table td:first-child, .styled-table th:first-child {
  padding-left: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}​ 

.styled-table td, .styled-table th{
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.styled-table tr {
  border-bottom: 1pt solid #d6deea;
  border-bottom-width: thin;
  padding-left: 1rem;

  /* border: 1px solid red; */

}

.styled-table tbody tr:hover {
  background-color: #f4eee3;
}

.styled-table .cell-align-right{
  text-align: right;
  padding-right: 1rem;
}


.styled-table-menu{
  font-size: 1rem;
  padding-right: 5px;
}

.styled-table-menu :hover{
  font-size: 1.25rem;
  color: #b5179e;
}









.styled-threedots-dropdown{
  font-size: 0.8rem;

}

.dynamic-table {
  table-layout:auto;
  margin-top: 2rem;
  margin-bottom: .5rem;
  font-size: 0.8rem;

  background-color: #f9fbfd;
  border-radius:10px;

  /*Testing Tabs */
  width: 100%;

  /* align-items: center; */
  /* padding: 0 7rem; */
  /* border-spacing: 1rem 0; */
    /* border: 1px solid red; */

}





/* .product-options-table tr td:nth-child(3) {
  border: 1px solid red;
 } */


.dashboard-content .dashboard-form .dynamic-table{
  margin-top: 1.25rem;
}

.dynamic-table th{
  text-transform: uppercase;
  color: #95aac9;
  font-size:.65rem;
  font-weight: 500;
  padding-top: 1rem;
}

/* .dynamic-table #journal-voucher th.account, .dynamic-table #journal-voucher th.description { */

/* #journal-voucher th.account, #journal-voucher th.description {
  width: 30%
} */

/* #journal-voucher th.account {
  width: 20%
}  */
#journal-voucher th.amount {
  width: 15%
}
#journal-voucher th.description {
  width: 30%
}



.dynamic-table td:first-child, .dynamic-table th:first-child {
/* .dynamic-table td:first-child { */

  padding-left: 1.5rem;
  /* padding-top: 1rem; */
  /* padding-bottom: 1rem; */
  /* border: 1px solid red; */
  text-align: center;

}​ 


.dynamic-table tbody{
  /* padding-left: 3rem; */


}

.dynamic-table tr {
  /* border-bottom: 1pt solid #d6deea; */
  /* border-bottom-width: thin; */
  /* padding-left: 1rem; */

}

.dynamic-table td, .dynamic-table th{
  padding-bottom: 1rem;
  /* padding: 0.5rem 0; */

  /* padding-bottom: 5px; */
  /* border: 1px solid red; */
  padding-left: 5px;
  padding-right: 5px;

}

.dynamic-table td input{
  /* border: 1px solid red; */
  background-color: #f9fbfd;

}

.dynamic-table td .MuiOutlinedInput-root{
  padding: 0;
  font-size: 0.9rem;
}

.dynamic-table td .MuiOutlinedInput-root .MuiAutocomplete-input{
  /* min-width: 10rem; */
  width: auto;
}

.dynamic-table .td-textbox input{
    /* border: 1px solid red; */
    /* padding: o.25rem; */
    padding: 7.5px;
    height: 1.4375em;
    font-size: 0.9rem;
    min-width: 100%;


}

/* .dynamic-table .product-options-autocomplete .MuiAutocomplete-inputRoot {
    flex-wrap: nowrap !important;
} */


.dynamic-table td .MuiOutlinedInput-root .MuiAutocomplete-endAdornment button{
  /* border: 1px solid red; */
  margin: 0;
}

.dynamic-table td button{
  vertical-align:middle;
  margin: 0;
  /* border: 1px solid red; */

}

.dynamic-table-menu{
  /* border: 1px solid red; */
  border: 0;
  font-size: 1.25rem;
  background-color: #f9fbfd;

}

.dynamic-table-menu {
  color: #1976d2;
  /* background-color: #1976d2; */
  /* border: 1px solid red; */
}

.dynamic-table-menu :hover{
  font-size: 1.25rem;
  color: #b5179e;
}

/* TABLE FOR PRODUCT OPTIONS */
.product-options-table { 
  table-layout: fixed;
  width: 100%
}

.product-options-table th:first-child {
  width: 5%;
 }

 .product-options-table tr th:nth-child(2) {
  width: 35%;
 }

.product-options-table tr th:nth-child(3) {
  width: 55%;
 }

 .product-options-table .product-options-autocomplete .MuiAutocomplete-inputRoot {
    flex-wrap: nowrap !important;
}

.product-options-table .product-options-autocomplete .Mui-focused {
  flex-wrap: wrap !important;
}

.product-options-table .product-options-autocomplete .MuiChip-outlined {
  /* border: 2px solid #e65100; */
  /* border: 1px solid #e76f51; */
  border: 1.5px solid #219ebc;

  

  /* border: 1.5px solid #0f4c5c; */

  

  /* fill: #ffc107; */
  /* background-color: #ffc107; */
  /* background-color: #219ebc; */

  /* color: white; */
  /* margin: 5px; */
  height: 95%
}



.dashboard-form .table-total {
  display: flex;
}

.dashboard-form .table-card{
  /* margin-left: auto; */
  display: flex;
  flex-direction: column;
  width: 50%;

  /* padding-right: 3.5rem; */
}
.dashboard-form .table-card button{
    /* border: 1px solid red; */
    font-weight: 300;
    font-size: 0.75rem;

}

/* .dashboard-form .table-card .AddRow button :hover{ */
/* .dashboard-form .table-card .AddRow button.active{ */
/* .dashboard-form .table-card .AddRow #AddRowButton :hover{ */
/* .dashboard-form .table-card .AddRow #AddRowButton .MuiButton-outlined { */
.AddRow #AddRowButton button {

  /* font-size: 1.25rem; */
  /* color: #b5179e; */
  /* background-color: #b5179e; */
  font-weight: 600;
  border: 1px solid red;


}


.dashboard-form .table-card .card-pretitle{
  text-transform: uppercase;
  color: #95aac9;
  font-size:.65rem;
  font-weight: 500;
}

.dashboard-form .table-card .error-text{
  text-transform: uppercase;
  /* color: #95aac9; */
  color: red;
  font-size:.65rem;
  font-weight: 500;
}

.dashboard-form .table-card .card-text{
  font-size:1.5rem;
  font-weight: 300;
}


.dashboard-form .table-card .card-pretitle, .dashboard-form .table-card .card-text, .dashboard-form .table-card .error-text{
  margin-left: auto;
  /* padding-right: 3.5rem; */
}

.dynamic-table .AddRow {
  /* border: 1px solid red; */
  /* margin-left: 2rem; */
  /* display: flex;   */
  /* width: 100%; */
  /* width: 20rem; */
}

.AddRow #AddRowButton{
    /* border: 1px solid red; */
    /* margin-left: auto; */
    /* text-align: right; */
    margin: 0;

}


.container-dashform{
  gap: 0;
  /* border: 1px solid red; */
  /* align-items: flex-start; */

  /* align-items: flex-start; */
  display: flex;
  flex-direction: column;
  /* display: grid; */
  /* height: 100%; */

}

.dashboard-form{
  display: grid;
  padding: 1.5rem;

}

.dashboard-content .dashboard-form{
  /* display: grid; */
  /* padding: 1.5rem; */
  padding: 0;
}


.dashboard-content .tab-content .dashboard-form{
  padding: 0;
  /* border: 1px solid grey */
  /* padding-top: 0; */
  /* background-color: #F9FBFD;
  border-radius: 10px; */
}

.dashboard-modal-wide{
  /* width:unset !important; */
  /* width: 50% !important; */
  /* border: 5px solid red */
}
.dashboard-modal-wide .ant-modal-content{
  /* border: 1px solid red; */
  width:40rem
}


/* .dashboard-error {
} */


.dashboard-error .error-header{
  margin-top: 2rem;
  font-size: 2.5rem;
  font-weight: 200;
}

.dashboard-modal .custom-input{
  margin-top: 1.75rem;
}

.dashboard-form-light{
  background-color: #F9FBFD;
  margin-top: 1rem;
  padding: 1rem;
}

.dashboard-form .grouped-fields-light{
  background-color: #F9FBFD;
  border-radius: 10px;
  padding: 1rem 1.5rem 1.5rem;
  margin-top: 1rem;
}

.dashboard-form .two-columns{
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 0.5rem;
}

/* .dashboard-form .two-columns > * {
  flex-grow: 1;
} */

.dashboard-pos .dashboard-form button{
  margin: 0;
}


.custom-input .MuiAutocomplete-endAdornment svg{
  /* color: #2196f3; */
  color: rgba(0, 0, 0, 0.35);

}

.cart-accordion-text{
  text-transform: uppercase;
  color: #95aac9;
  font-size:.75rem;
  font-weight: 500;
}





/* .dashboard-form .custom-tree-select{
  height: 3.5rem;
  margin-top: 1.5rem;
  font-size: 0.9rem;
} */


/* .dashboard-form .custom-tree-select .ant-select-selector {
  border: 1px solid red;
}  */


.dashboard-form .custom-typography{
  /* padding: 0.5rem 1.5rem 1.5rem; */
  margin-top: 0.5rem;
}

/* .dashboard-main form {
  /* margin-top: 1rem; */
  /* margin-bottom: 1rem; 
} */

/* .dashboard-main form button{ */


.dashboard-form .custom-input, .dashboard-form .ant-picker {
  margin-top: 1.5rem;
  /* font-size: 0.5rem; */
}


.dashboard-form .custom-switch span,
.dashboard-form .custom-input label,
.dashboard-form .custom-input input,
.dashboard-form .custom-input span,
.dashboard-form .custom-select,
.custom-modal .dashboard-form .custom-input {
  font-size: 0.9rem;
}

.dashboard-form .custom-switch-container{
  margin-top: 1rem;
  display: flex;
  /* border: 1px solid grey; */
  border-radius: 4px;
  width: 100%;
  flex-direction: row;
  align-items: center;
  /* padding: 0.5rem; */
  padding: 8px 14px;
  background-color: #f0f5ff;
  border: 1px solid #f0f5ff;
  /* font-weight: 400; */
}

.dashboard-form .custom-switch-container-white{
  background-color: #F9FBFD;
}

.custom-switch-container .MuiFormControlLabel-root{
  /* border: 1px solid red; */
  margin-right: 0;
}

.dashboard-form .custom-switch{
  margin-top: 1rem;
}

.dashboard-form .custom-switch .MuiFormControlLabel-label{
  font-size: 0.9rem;
  color: #00798c;

  /* font-size: 0.8rem;
  color: #95aac9; */
  /* text-transform: uppercase; */
}

.dashboard-form .custom-auto-complete-combo {
  display: flex;
  flex-direction: row;
}

.dashboard-form .custom-auto-complete-combo .MuiAutocomplete-root{
  /* width: 100%; */
  width: 92.5%
}

.dashboard-form .custom-auto-complete-combo .add-new{
  border:1px solid rgba(0, 0, 0, 0.20);
  margin-top: 1.5rem;
  width:7.5%;
  margin-left: 0.25rem;
  /* padding: unset; */
  border-radius: 5px;
}

/* .dashboard-form .custom-auto-complete-combo .add-new:hover{
    border:solid 1px #2196f3; 
    background-color: unset;
} */


.dashboard-form .custom-auto-complete-combo .add-new svg{
  color: rgba(0, 0, 0, 0.20);
}




/* .custom-radio{
  border: 1px solid grey;
  margin-top: 1rem;
  padding: 0.25rem;
  background-color: lightblue;
} */


.custom-radio-group {
  margin-top: 1rem;
  /* font-size: 0.9rem; */
  /* padding: 1px; */
  /* padding-top: 0; */
}

.custom-radio span {
  font-size: 0.85rem;
  /* padding: 1px; */
  padding-right: 1px;
  /* padding-top: 0; */
}

.custom-tab-container{
  /* text-transform: none; */
  border-bottom: 1px solid lightgray;
}

.custom-tab{
  text-transform: none;
}

.custom-tab-panel{
  padding-left: 0;
  padding-right: 0;
}

/* MUI select list */
.MuiMenu-list li {
  font-size: 0.9rem;
}


.cust-auto-complete {
  display: flex;
  align-items: center;
  align-content: center;
  /* border:1px solid blue; */

}



/* Correct bug in misaligned dropdown button -> https://stackoverflow.com/a/70082097  */

.custom-input .MuiAutocomplete-endAdornment{
  margin-top: -16px;
}

/* .custom-input .MuiAutocomplete-endAdornment svg{
  color: #2196f3;
} */



/* Ant Design Responsive Date Range Picker */
.dashboard-form .ant-picker{
  font-size: 2rem;
  height: 1.4375em;
  /* width: 50%; */
  width: 100%;
  border: 1px solid #adb5bd;
  border-radius: 4px;
  background-color: #edf3f8;
  ;
}

.dashboard-form .ant-picker span{
  /* color: #9c27b0; */
  /* color: #F6B21B; */

}

.ant-picker-panels { 
  flex-direction: column;
} 

.dashboard-form .custom-upload-card .ant-upload{
  /* border: 1px solid red */
  background-color: #F8FAFC;
}

.dashboard-form button{
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 5px;
}

.dashboard-form .button-border-top{
  width: 100%;
  border-top: thin solid #d6deea;
  margin-top: 1rem;
}

.dashboard-form .button-group{
  display: flex;
  /* justify-content: space-between; */
}

.custom-pill-chip{
  height: 18px;
  /* border: 1px solid red; */
}
.custom-pill-chip span{
  font-size: 0.65rem;
}



.newtxn-form {
  /* margin-top: 1rem; */
}

.newtxn-form label {
  font-size: 0.9rem;
  margin-top: 1.5rem;
}

.newtxn-form input, .newtxn-form select{
  font-size: 0.9rem;
  line-height: 2rem;
}

.newtxn-form button{
  margin-top: 1rem;
  margin-bottom: 1rem;
}


.custom-pagination{
  /* margin-left: 5%;
  margin-right: 5%; */
  margin-top: 1rem;
  display: flex;
  align-items: center;

  justify-content: space-between;
  /* justify-content: center; */

  background-color: #f9fbfd;
  height: 60px;
}

.custom-mui-pagination{
  /* margin-left: 5%;
  margin-right: 5%; */
  margin-top: 1rem;
  display: flex;
  align-items: center;

  /* justify-content: space-between; */
  justify-content: center;

  background-color: #f9fbfd;
  height: 60px;
}


.custom-mui-pagination ul li button{
  /* background-color: #fb8c00; */
  /* color: white; */
  /* border: solid red 1px; */
  font-size: 0.75rem;
  /* font-weight: 600; */
}

.custom-mui-pagination ul li button:focus {
  border: #fb8c00 solid 2px;
  font-weight: 600;
  font-size: 0.75rem;
  background-color: #f9fbfd;

  /* background-color: #F6B21B */
  /* background-color: #fb8c00; */
  /* color: white; */
}
.custom-mui-pagination ul li button:hover {
  /* border: #fb8c00 solid 2px; */
  /* border: red solid 1px; */
}


.custom-pagination-pageno{
  display: flex;
  background-color: #f9fbfd;
  /* align-items: center; */
  /* align-items: stretch; */
  /* border: 1px solid red; */
}

.custom-pagination-pageno .page-item .page-link  {
  background-color: #f9fbfd;
  border: 0;
  /* border: 1px solid red; */
}

/* .custom-pagination-next {
  width: 10vw;
  display: flex;
  justify-content: center;
  border-left: 1px solid #dee2e6;
} */



.custom-pagination-next .page-link, .custom-pagination-prev .page-link {
  background-color: #f9fbfd;
  border: 0;
  width: 10vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  /* border-left: 1px solid #dee2e6; */
  /* border: 1px solid red; */
}
.custom-pagination-prev .page-link{
  border-right: 1px solid #dee2e6;
}

.custom-pagination-next .page-link{
  border-left: 1px solid #dee2e6;
}


/* .custom-pagination-pageno .page-link{
  height: 100%; 
  align-self: stretch;
} */

.custom-pagination-pageno .page-item .page-link{
  height: 100%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  /* align-self: stretch; */
}


/* .custom-pagination-next:hover,   
.custom-pagination-next .page-link:hover, */
.custom-pagination-pageno .page-item .page-link:hover
{
  color: white;
  background-color: #b5179e;
}

.custom-pagination-prev .page-link:hover,
.custom-pagination-next .page-link:hover
{
  border-bottom: 2px solid #b5179e;
} 


.footer-bg-lightblue{
  /* height: 400px; */
  /* height: 100vw; */
  /* max-width: 100%; */
  /* background-color: #f9f8fb; */
  /* min-height: 100%; */
  background-color: #f0f5ff;
  display: flex;
  justify-content: center;
  /* align-items: end; */
  align-items: flex-end;
  align-self: flex-end;
  /* border: 1px solid red; */
  height: 75px;
}

.footer-bg-lightblue .footer{
  /* margin-top: 1rem; */
  padding: 1rem 0;
}

.footer-bg-lightblue .footer .footer-logo{
  /* font-size: 1.75rem; */
  display: flex;
  /* gap: 5px; */
}

.footer-bg-lightblue .footer .footer-logo svg{
  font-size: 2rem;
  display: flex;
  /* gap: 5px; */

}

.footer-bg-lightblue .footer .footer-logo a:hover{
  color: #b5179e;
}


.footer{
  display: flex; 
  gap: 5%;
  flex-direction:column; /*This is only for mobile devices*/
  padding-bottom: 2rem; 
}

/*This container is not used in tablets and desktops*/
.footer-logo-container{
  /* width: 40% */
  /* border:1px solid red; */

}

/*This container is not used in tablets and desktops*/
.footer-text-container{
  /* width: 60%; */
  display: flex;
  /* justify-content: space-between; */
  /* padding-right: 5rem; */
  gap: 10%;
  /* padding: 2rem 0; */
  /* border:1px solid red; */
  /* padding-top: 1rem; */
  margin-top: 0.5rem;
}

.footer-section{
  /* width: 25%; */
  width: 100%;
}

.footer-logo{
  /* font-size: 1.5rem; */
  font-size: 1.75rem;
  display: flex;
  gap: 7.5px;

  /* margin-right: 10vw; */
}

.footer-section-title{
  font-size: 0.75rem;
  /* font-size: 0.85rem; */

  /* font-weight: 500; */

  /* color: #95aac9; */
  /* text-transform: uppercase; */
  /* border-bottom: 1px solid #95aac9; */
  border-bottom: 1px solid #b2dfdb;
  padding-right: 2rem;
  line-height: 1.75rem;
}

.footer-list{
  list-style-type: none;
  padding: 0;
  margin: 0;
  line-height: 1.15rem;
  /* line-height: 1.5rem; */
}

.footer-list a{
  text-decoration: none;
  color: #2E5A81;
  font-size: 0.7rem;
  /* font-size: 0.75rem; */
  font-weight: 300;
}

.footer-list a:hover{
  color: #b5179e;
  font-weight: 500;
}


.footer-list li{
  /* color: #2E5A81; */
  /* color: #607d8b; */
  /* font-size: 0.7rem; */
  /* font-size: 2rem; */

  /* line-height: 1.2rem; */

}

.footer-list li svg{
  padding-right: 2pt;
}

.footer-services{
  font-size: 0.7rem;
  /*padding-top: 5px; */

  /* background-color: grey; */
}



.footer-section-company{
  /* Hidden on Mobile */
  /* display: none;  */
}

.footer-section-legal{
  /* Hidden on Mobile */
  display: none; 
}


.footer-copyright{
  font-size: 0.7rem;
  margin-top: 5px;
}





/*Responsive design for Tablets*/
@media screen and (min-width: 768px) and (max-width: 1024px) {

.navbar-brand img{
  /* object-fit: contain;  */
  /* height: 100%; */
  height: 50px;

}
    

.hero-bg-lightblue{
  /* height: 45vw; */
  height: 70vw;

}

.hero-title{
  font-size: 2.95rem;
  /* padding: 0 5rem; */
  padding: 0 10%;

}

.hero-subtitle{
  /* padding: 0 20vw; */
  padding: 0 10%;
}

.hero-img{
  width: 70vw; 
}

.features-card {
  min-width: 50%;
  font-size: 0.85rem;
    /* height: 150px; */
    /* background: rgba(255, 240, 246, 0.4); */
  padding: 1rem;
    /* border-left: 1px solid #b5179e; */
}

.features-card-title {
    font-size: 0.85rem;
    /* font-weight: 600;
    margin-bottom: 0.25rem;
    margin-top: 0.25rem; */
}

.cta{
  height: 100px;
  flex-direction: row;
  /* justify-content: space-around; */
}

.cta-text{
  /* font-family: 'Poppins', sans-serif; */
  width: 70%;
  color: white;
  font-size: 0.8rem;
  text-align: left;
  padding: 0;

  /* font-weight: 500; */
  /* margin-left: 5%; */
}

.clients-logo{
  height: 50px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

/* .auth-form{
  grid-column: 6 / -1;
}  */

.auth-form form{
  padding: 0 25%;
}

.landing-form{
  grid-column: 6 / -1;
}

.auth-img{
  display: flex;
  grid-column: 1 / 6;

}

.landing-form-bg-purple{
  display: flex;
  grid-column: 1 / 6;

}


.footer-section-company{
  display: block; 
}

.footer-section-legal{
  display: block; 
}

}





/*RESPONSIVE DESIGN FOR DESKTOPS AND HIGHER*/
@media screen and (min-width: 1025px ) {

.navbar-brand img{
  /* object-fit: contain;  */
  /* height: 50px; */
  /* border: red solid 1px; */
  height: 22.5px;


}



.navbar-icon{
  height: 45px;
}

.navbar .login-button{
  height: 50px;
}


.hero-bg-lightblue{
  height: 35vw;
}

.hero-title{
  /* font-size: 3rem; */
  font-size: 2.5rem;

  line-height: 3rem;
  /* padding: 0 5rem; */
  /* padding: 0 15%; */
  padding: 0 10%;

}

.hero-subtitle{
  /* padding: 0 20vw; */
  /* padding: 0 15%; */
  /* padding: 0 10.5%; */
  padding: 0 11%;


}

.hero-img{
  /* width: 60vw;  */
  width: 60vw; 

}

.features{
  /* margin-top: 2rem; */
  display: flex;
  /* gap: 1.5rem; */
}


.features-card {
  min-width: 0;
  font-size: 0.75rem;
  /* height: 150px; */
  /* background: rgba(255, 240, 246, 0.4); */
  padding: 1rem;
  /* border-left: 1px solid #b5179e; */
}

.features-card-title {
  font-size: 0.75rem;

}

.clients{
  margin-bottom: 1.5rem;
}

.clients-text{
  /* font-size: 0.65rem; */
  padding-left: 25%;
  padding-right: 25%;
  /* text-align: center; */
  /* margin-bottom: 1.5rem; */
}

.clients-logo{
  height: 50px;
  /* padding-left: 1.5rem;
  padding-right: 1.5rem; */
  padding-left: 1rem;
  padding-right: 1rem;
}


.cta{
  height: 100px;
  /* background-color: #1769aa; */
  /* display: flex; */
  flex-direction: row;
  gap: 0;
  /* align-items: center; */
  /* justify-content: space-around; */
}

.cta-text{
  /* font-family: 'Poppins', sans-serif; */
  width: 70%;
  color: white;
  font-size: 0.8rem;
  text-align: left;
  padding: 0;

  /* font-weight: 500; */
  /* margin-left: 5%; */
}

.container-auth{
  visibility: visible;

}

/* .auth-form{
  grid-column: 7 / -1;
} */

.auth-form form{
  padding: 0 35%;
}

.landing-form{
  grid-column: 7 / -1;
}

.auth-img{
  display: flex;
  grid-column: 1 / 7;

}

.landing-form-bg-purple{
  display: flex;
  grid-column: 1 / 7;

}

/* #dashboard-landing{
  flex-wrap: wrap;
} */
.dashboard-container{
  grid-template-columns: minmax(200px, 17.5%) auto;

}

/* .dashboard-menu{
  display: flex;

} */

.dashboard-form-compact{
/* .dashboard-content .dashboard-form-compact{ */
  margin-left: 10%;
  margin-right: 10%;
}

/* .dashboard-content-compact{
  margin-left: 17.5%;
  margin-right: 17.5%;
} */

#dashboard-charts .dashboard-card{
  flex: 1 1 49%; /* grow | shrink | basis */
  max-width: 50%;
}


#dashboard-landing .dashboard-card{
  /* Limiit number of columns on dashboard landing page */
  /* flex-basis: 49%; */
  /* flex-basis: 33%; */
  flex: 1 1 30%; /* grow | shrink | basis */
  max-width: 33.33%;


}

#dashboard-charts .dashboard-card{
  /* Limiit number of columns on dashboard landing page */
  /* flex-basis: 26%; */
  /* width: 33%; */
  /* align-items:center; */
  flex-basis: 49%;
  height: 250px;
}

/* Ant Design Responsive Date Range Picker */
/* .dashboard-form .ant-picker,.dashboard-form .custom-daterange-picker { */
.dashboard-form .custom-daterange-picker {
  width: 50%;
}


/* .dashboard-form .ant-picker-range {
  width: 600px;
}  */

.ant-picker-panels { 
  flex-direction:row;
} 

.custom-pagination{
  margin-top: 0;
  
}

.footer{
  flex-direction:row;
  padding-bottom: 0;
}

.footer-section{
  width: 25%
}

.footer-list{
  line-height: 1.5rem;
}

.footer-list a{
  /* text-decoration: none; */
  /* color: #2E5A81; */
  font-size: 0.75rem;
  /* font-weight: 300; */
}

.footer-section-company{
  display: block; 
}

.footer-section-legal{
  display: block; 
}

}



