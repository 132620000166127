
.MuiAutocomplete-option {
  font-size: 0.9rem;
  /* border: 1px solid red; */
  /* background-color: unset !important ; */
  /* background-color: #FFFFFF ; */
  /* margin-top: 1rem; */
}


/* #custom-MuiAutocomplete .MuiAutocomplete-option{
  border: 1px solid red ;
} */

/* .MuiAutocomplete-option:hover {
  background-color: #F0F5FF !important
}

.MuiAutocomplete-option.Mui-focused {
  background-color: #F0F5FF !important; 
} */

