.home-container {
    /* border: 1px solid red; */

}


.home-container .navbar{
    /* border: 1px solid red; */
    margin: 0.25rem 1rem;
}

.home-container .navbar {
    /* border: 1px solid red; */
    margin: 0.25rem 1rem;
}

.home-container .hero{
    height: 80vw;
    /* background-color: #ffd639; 
    background-color: #ededed; */
    display: flex;
    flex-direction: row;
    padding: 0.25rem 7.5%;
}


.home-container .hero-bg-light-grey{
    background-color: #ededed;
}

.home-container .hero-bg-cyan{
    background-color: #b2ebf2;
}

.home-container .hero-bg-yellow{
    background-color: #ffd639;
}

.home-container .hero-container{
    /* height: 80vw; */
    /* background-color: #ffd639; */
    /* background-color: #ededed; */
    display: flex;
    flex-direction: row;
    padding: 0.25rem 7.5%;
}



.home-container .hero-text-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.home-container .hero-sub-title{
    font-size: 0.8rem;
    /* font-weight: 200; */
    /* padding: 0 10%; */
    /* font-size: 18px; */
    /* line-height: 30px; */
    /* color: #182F43; */

    /* padding-top: 1rem; */
    color: #1570ef;

    font-weight: 300;
    text-transform: uppercase;
    line-height: 2rem;

}

.home-container .hero-title{
    font-size: 3rem;
    /* font-size: 2rem; */
    /* font-weight: 300; */
    letter-spacing: -1px;


    padding: 0 10%;
    /* border: 1px solid red; */
    text-align: unset;
    margin: unset;
    padding: unset;
}

.home-container .hero-text{
    font-size: .85rem;
    font-weight: 200;
    /* padding: 0 5rem; */
    /* font-size: 18px; */
    /* line-height: 30px; */
    /* color: #182F43; */
    padding-right: 2rem;
    padding-top: 1rem;
}

.home-container .hero-img-container{
    /* visibility: hidden; */
    display:none;
}

.home-container .hero-img-container img{
    width: 100%;
}

.home-container .features{
    /* display: flex; */
    /* height: 25vw; */
    /* background-color: #f0f5ff; */
    /* background-color: #F3FAFF; */
    height: unset;
    /* padding: 0.25rem 7.5%; */
    padding: 3rem 7.5%;

    flex-wrap: wrap;
    gap: 0.5rem;

}

.home-container .features-card{
    border: unset;
    padding: unset;
    height: unset;

    background-color: #EBF2F8;  
    /* background-color: #84AED3; */
    /*width: calc(25% - 0.5rem); /* Optional for a specific number of cards per row*/
    /* display: flex;
    flex-direction: column; */
}

.home-container .feature-bullet{
    /* margin: 4rem 0; */
    /* background-color: #EBF2F8;   */
    width: 1.25rem;
    height: 1.25rem;
    background-color: #219ebc;
    margin: 1rem;

    /* border: 1px solid red; */
}

.home-container .features-card-title{
    padding: unset;
    padding-left: 1rem;
    /* padding: 0.5rem 1rem; */
    /* /* font-size: .85rem; */
    /* font-size: 0.9rem;
    font-weight: 400;  */
}

.home-container .features-card-text{
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;


    /* padding: 0.5rem 1rem; */
    /* padding: 1rem; */

    font-size: .75rem; 
    font-weight: 200;
    line-height: 0.9rem;
}

.home-container .footer{
    /* border: 1px solid red; */

    padding: 0.25rem 7.5%;
    /* background-color: #F3FAFF; */
    background-color: #EBF2F8

}

.home-container .cta{
    /* margin: 0.25rem 7.5%; */
    margin-top: 2rem;
    margin-bottom: 2rem;
    /* background-color: #EBF2F8 */
}



/*Responsive design for Tablets*/
@media screen and (min-width: 768px) and (max-width: 1024px) {
    /* .home-container .hero{
        height: 35vw;
        padding: 0.25rem 7.5%;
    } */
    .home-container .hero-crm{
        height: 35vw;
        padding: 0.25rem 7.5%;
    }
    .home-container .hero-pos{
        height: 35vw;
        padding: 0.25rem 7.5%;
    }
    .home-container .hero-text-container{
        width: 60%;
    }
    .home-container .hero-img-container{
        display: block;
        width: 40%;
        align-self: flex-end;
    }

    .home-container .features-card{
        width: calc(33.33% - 0.5rem); 
        /* Optional for a specific number of cards per row*/
    }
}


/*RESPONSIVE DESIGN FOR DESKTOPS AND HIGHER*/
@media screen and (min-width: 1025px ) {

    .home-container .hero{
        height: 25vw;
        padding: 0.25rem 7.5%;
    }  

    .home-container .hero-text-container{
        width: 60%;
    }

    .home-container .hero-img-container{
        /* visibility:visible; */
        display: block;
        width: 40%;
        align-self: flex-end;
    }

    .home-container .features-card{
        width: calc(25% - 0.5rem); /* Optional for a specific number of cards per row*/
    }

    .home-container .cta{
        margin: 0.25rem 7.5%;
        margin-bottom: 3rem;
        /* background-color: #EBF2F8 */
    }

}