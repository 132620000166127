

.message-box{
    /* border: 1px solid red */
    /* font-size: 0.75rem; */
}

.message-box th:nth-child(3)
{
    width: 55%;
  /* padding-left: 1rem; */
}

.message-box th:first-child
{
    /* padding-left: 1rem; */
    width: 17.5%;


}

.message-box th:last-child, .message-box td:last-child
{
    padding-left: 1rem;
    /* border: 1px solid red */

}

.message-box td:nth-child(1)
{
    /* width: 50%; */
    /* padding-left: 1rem; */
    padding-top: 0.5rem;
}


.message-box td 
{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    vertical-align: top;
}


.filter-customers .chip-list-container{
    width: 100%; 
    overflow-x: auto;
    white-space: nowrap;

    /* For Firefox */
    scrollbar-width: thin;
}


/* For WebKit browsers (Chrome, Safari, newer versions of Edge) */
.chip-list-container::-webkit-scrollbar {
    height: 6px; /* Adjust this value to change the thickness */
}

.filter-customers .chip-list-container .chip-list {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 1.15rem; 
}


.filter-customers .chip-list-container .chip-list .custom-chip{
    margin-right: 0.25rem;
    /* flex: 0 0 auto; */
}



