.filter-meters .chip-list-container {
    width: 100%; 
    overflow-x: auto;
    white-space: nowrap;

    /* For Firefox */
    scrollbar-width: thin;
}


/* For WebKit browsers (Chrome, Safari, newer versions of Edge) */
.chip-list-container::-webkit-scrollbar {
    height: 6px; /* Adjust this value to change the thickness */
}

.filter-meters .chip-list-container .chip-list {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 1.15rem; 
}


.filter-meters .chip-list-container .chip-list .custom-chip{
    margin-right: 0.25rem;
    /* flex: 0 0 auto; */
}