.custom-tree-select {
    margin-top: 1.5rem;
}

.ant-select-selector {
    /* border: 1px solid blue; */
    /* max-height: 51px; */
    /* overflow: auto; */
}

.dashboard-form .ant-select-single.ant-select:not(.ant-select-customize-input) .ant-select-selector{
    /* border: 1px solid blue; */
    height: 3.5rem;
    background-color: #F9FBFD;
}
  
.ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
    /* border: 1px solid green;  */
    /* align-items: center;
    align-content: center;*/
    margin-top: 0.75rem; 
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{
    margin-top: 0.8rem; 
    /* align-items: center; */
    /* border: 1px solid red;  */
}

.ant-modal-body{
    padding-top: 0.75rem;
}

.ant-modal-body .dashboard-form{
    /* margin: 0; */
    /* padding: 0; */
    padding-top: 0;
    padding-bottom: 0;
    /* border: 1px solid red; */
}

/* .pos-products-container form .custom-input .MuiAutocomplete-endAdornment{ */

/* .ant-modal-body .dashboard-form form{
    margin-top: 0;
    border: 1px red solid;
} */