
.property-new {
    /* border: 1px solid red; */
}

/* .property-new .ant-steps {
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.property-new .ant-steps-finish-icon svg{ 
    vertical-align: unset; 

} */

.ant-steps {
    margin-top: 1rem;
    margin-bottom: 1rem;
    /* margin: 1rem 0 */
}

.ant-steps-finish-icon svg{ 
    /* border: 1px solid red; */
    vertical-align: unset; /*Correct misaligned icon */
}

.styled-threedots-dropdown .MuiListItemIcon-root {
    /* border: 1px solid red; */
    /* font-size: 1.25rem; */
    /* margin-right: 0; */
    min-width: 25px;
}


.styled-threedots-dropdown .MuiListItemIcon-root svg {
    /* border: 1px solid red; */
    font-size: 1.15rem;
    margin-right: 0;
}

.styled-threedots-dropdown .MuiListItemText-root span {
    /* border: 1px solid red; */
    font-size: .85rem;
    margin-left: 0;
    /* size: 0.5rem; */
}

/* .lease-new .ant-steps .ant-steps-item:last-child {
    flex: 1;
} */



