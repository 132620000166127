.hover-search-box {
    position: relative;
    display: inline-block;
    /* width: 10rem; */
    /* border: 1px solid red; */


  }
  
.search-icon-container {
    display: flex;
    /* align-items: center; */
  }
  
.search-icon-button {
    background: none;
    border: none;
    cursor: pointer;
  }
  
.search-icon {
    /* font-size: 20px; */
}

.input-container {
    /* position: absolute; */
    display: flex;
    align-items: center;
    /* border: 1px solid red; */

    right: 0;
    display: flex;
    align-items: center;
    /* opacity: 0; */
    pointer-events: none;
    transition: opacity 0.3s ease;
  }
  
.hover-search-box:hover .input-container {
    opacity: 1;
    pointer-events: auto;
    border: 1px solid #00bcd4;
    border-radius: 25px;
}
  
.search-input {
    flex-grow: 1;
    width: 0;
    padding: 10px;
    font-size: 16px;
    /* border: 1px solid #ccc; */
    /* border-radius: 4px; */
    border: 0;
    transition: width 0.3s ease;
    background: transparent;
    display: none;
}

  
.hover-search-box:hover .search-input {
    /* width: 200px;  */
    width: 100%;
    padding-right: 30px; 
    display: block;
}

.hover-search-box:hover .clear-button {
  /* width: 200px;  */
  display: block;
}

.search-input:focus{
    /* border: 1px solid red; */
    outline: none; /* Remove the default focus outline */
}
  
.clear-button {
    /* position: absolute; */
    right: 10px;
    background: none;
    border: none;
    /* font-size: 16px; */
    cursor: pointer;
    outline: none;
    color: #aaa;
    display: none;
}
  
  .clear-button:hover {
    /* color: #333; */
  }

  .clear-icon{
    /* border: 1px solid red; */
    /* width: 1rem;
    height: 1rem; */
    /* size: 3rem */
  }

  .clear-button .MuiSvgIcon-root{
    width: 1.5rem;
    height: 1.5rem;

  }